import { Canvas } from '@react-three/fiber'
import IntroModel from '../canvas/models/IntroModel';
import { getIntro } from '@/sanity/sanity-utils';
import { useEffect, useState } from 'react';
import Image from 'next/image';

export interface IIntroContent {
  title: string;
  subtitle: string;
  image: string;
  alt: string;
  description: string;
}


const Intro = () => {

  const [intro, setIntro] = useState<IIntroContent>();

  async function getIntroContent() {
    const introContent = await getIntro();
    console.log(introContent)
    setIntro(introContent[0]);
  }

  useEffect(() => {
    getIntroContent();
  }, []);

  return (
    // <!-- Intro -->
    <section className="bg-gradient-to-r from-[transparent] to-[#F5F8FA] py-16 dark:to-[#101436]">
      <div className="container">
        <div className="lg:flex lg:justify-between">
          {/* <!-- Image --> */}
          <div className="relative h-[400px] lg:w-[45%]">
            {
              intro?.image ? (
                <Image src={intro.image} alt={intro.alt} width={100} height={100} />
              ) : (
                <Canvas>
                  <IntroModel />
                </Canvas>
              )
            }
          </div>
          {/* <!-- Info --> */}
          <div className="py-10 lg:w-[55%] lg:pl-24">
            <h2 className="mb-6 text-3xl font-display text-jakarta-700 dark:text-white">
              {intro?.title}
            </h2>
            <p className="mb-8 text-lg leading-normal normal-case dark:text-jakarta-300">
              {intro?.subtitle}
            </p>
            <p className="dark:text-jakarta-300">
              {intro?.description}
            </p>
            {/* <TradeVolume /> */}
          </div>
        </div>
      </div>
    </section>
    // <!-- end intro -->
  );
};

export default Intro;
