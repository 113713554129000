import Faq from "./Faq";
import VideoBlock from "./VideoBlock";

const Participate = () => {
  return (
    <>
      {/* <!-- Video / FAQ --> */}
      <section className="bg-light-base py-24 dark:bg-jakarta-800">
        <div className="container">
          <div className="mx-auto mb-8 max-w-xl text-center">
            <h2 className="mb-6 text-center font-display text-3xl font-medium text-jakarta-700 dark:text-white">
              The AI Inquirer
            </h2>
            <p className="text-lg dark:text-jakarta-300">
              Your Guide to Navigating the World of Artificial Intelligence.
            </p>
          </div>
          <div className="lg:flex lg:flex-nowrap">
            <div className="lg:w-[59%]">
              <VideoBlock />
            </div>
            {/* End VideoBlock */}

            <div className="lg:w-[41%] lg:pl-24">
              <p className="mb-6 dark:text-jakarta-300">
                Get ready to revolutionize the way you work with AccessPoint.ai,
                the one-stop shop for all things AI. From automating repetitive tasks to improving decision-making,
                our platform offers a diverse range of AI-based tools to enhance your productivity and efficiency.
                And with our comprehensive FAQ section,
                you&apos;ll have all the answers you need to fully leverage the power of AI. So why wait?
                Start exploring the limitless possibilities of AI today with accessPoint.ai.
              </p>

              <Faq />
              <a
                href="#"
                className="inline-block text-sm font-bold text-accent"
              >
                Read More
              </a>
            </div>
            {/* End Faq */}
          </div>
        </div>
      </section>
      {/* <!-- end video / faq -->   */}
    </>
  );
};

export default Participate;
