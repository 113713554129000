import { contentData } from "data/benefit_data";
import Image from "next/image";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const BenefitContent = () => {
  // TODO: orbit images for each item
  // TODO: icons for each item

  return (
    <Tabs>
      <div className="lg:flex lg:flex-nowrap lg:space-x-10 benefit">
        <div className="lg:w-[43%]">
          {/* <!-- tabs --> */}

          <TabList className="nav nav-tabs mb-12 space-y-2">
            {
              contentData.map((item) => {

                return (

                  <Tab className="nav-item" key={item.id}>
                    <button
                      className="nav-link nav-link--style-2  group relative flex w-full border-jakarta-100 p-4 text-left dark:border-jakarta-600 dark:bg-transparent"
                      type="button"
                    >
                      <div>
                        {/* Icons should be here */}
                      </div>
                      <div>
                        <span className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white">
                          {item.title}
                        </span>
                        <div className="nav-link-content hidden">
                          <p className="text-jakarta-500 dark:text-jakarta-300">
                            {item.body}
                          </p>
                        </div>
                      </div>
                    </button>
                  </Tab>
                )
              })
            }
          </TabList>
        </div>
        {/* End tablist */}

        <div className="flex items-center justify-center lg:w-[57%]">
          {/* <!-- content --> */}
          <div className="tab-content flex-1">
            {
              contentData.map((item) => {
                return (
                  <TabPanel key={item.id}>
                    <div className=" relative">
                      <figure className="flex items-center justify-center">
                        <Image
                          width={600}
                          height={600}
                          src={item.image}
                          alt={item.title}
                          className="rounded-full border border-jakarta-100 p-14 dark:border-jakarta-600"
                        />
                        <Image
                          width={600}
                          height={600}
                          src={item.orbitImage}
                          alt={item.title}
                          className="absolute animate-spin-slow"
                        />
                      </figure>
                    </div>
                  </TabPanel>
                )
              })
            }
          </div>
        </div>
      </div>
    </Tabs>
  );
};

export default BenefitContent;
