export const faq_list = [
    {
        id: 0,
        "question": "What is accesspoint.ai?",
        "answer": "accesspoint.ai is a platform that aims to connect people from all over the world to the potential and benefits of artificial intelligence. Our goal is to make AI accessible to everyone, regardless of location or background, in order to create a more equal and prosperous society."
    },
    {
        id: 1,
        "question": "How does accesspoint.ai work?",
        "answer": "accesspoint.ai provides a range of AI-based tools and services that can be used by individuals and organizations in various industries. Our tools are designed to automate repetitive tasks, aid in data analysis and decision-making, and improve overall productivity and efficiency."
    },
    {
        id: 2,
        "question": "Who can use accesspoint.ai?",
        "answer": "Our platform is suitable for a wide range of users, including professionals in the technology and financial industries, researchers and academics, small business owners and entrepreneurs, medical professionals, educators, creative professionals, content producers, personal trainers, psychiatrists, and students."
    },
    {
        id: 3,
        "question": "What kind of AI-based tools does accesspoint.ai offer?",
        "answer": "Our platform offers a variety of AI-based tools, including automated data analysis, decision-making support, customer service, inventory management, marketing, medical diagnosis and monitoring, educational support, creative inspiration, and content optimization."
    },
    {
        id: 4,
        "question": "How does accesspoint.ai ensure the privacy and security of its users?",
        "answer": "accesspoint.ai takes the privacy and security of our users very seriously. We use state-of-the-art encryption and secure servers to protect our users' data, and we have strict policies in place to prevent unauthorized access or misuse of that data. Additionally, all of our AI-based tools are designed with privacy in mind and are fully compliant with relevant regulations and standards."
    },
    {
        id: 5,
        "question": "How can I get started with accesspoint.ai?",
        "answer": "Getting started with accesspoint.ai is easy. You can visit our website to learn more about our platform and the services we offer. You can also sign up for an account and start using our AI-based tools right away. If you have any questions or need help getting started, you can contact us at info@accesspoint.ai."
    },
    {
        id: 6,
        "question": "How much does it cost to use accesspoint.ai?",
        "answer": "accesspoint.ai offers a range of pricing options to suit different users and budgets. You can choose a plan that best fits your needs and budget. Contact us to know the prices or you can visit our pricing page on the website to learn more."
    },
    {
        id: 7,
        "question": "What are the benefits of using accesspoint.ai?",
        "answer": "accesspoint.ai offers a range of benefits, including increased productivity and efficiency, improved decision-making and data analysis, automation of repetitive tasks, and access to cutting-edge AI technology. With our platform, you can take advantage of the many benefits of AI without needing to have extensive knowledge or expertise in the field."
    },
    {
        id: 8,
        "question": "Does accesspoint.ai have any partnerships or collaborations?",
        "answer": "accesspoint.ai is continually seeking out new partnerships and collaborations in order to expand our reach and improve our services. We work closely with leading organizations and experts in various fields to ensure that our platform is always up-to-date and relevant."
    },
    {
        id: 9,
        "question": "Is accesspoint.ai available in different languages?",
        "answer": "Currently, accesspoint.ai website is available in English, Arabic, Turkish languages, but our AI-based tools support multiple languages and we're working on expanding the support to more languages as well."
    }
]