import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function IntroModel(props) {

    const { scene, animations } = useGLTF('/models/butterfly.glb', true)
    const cycleModel = useRef();
    const { actions } = useAnimations(animations, cycleModel);

    useEffect(() => {
        if (!cycleModel.current) return
        actions.Animation.play();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <group {...props}>
            <ambientLight />
            <primitive
                scale={38}
                position-y={-2.2}
                position-x={0}
                rotation-y={Math.PI / 6}

                ref={cycleModel} object={scene}
            />
        </group>
    )
}
