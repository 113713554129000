export const contentData = [
    {
        id: 0,
        title: 'Authenticity in Data',
        body: "By pairing blockchain and AI, we can ensure authenticity and reliability in the data used by AI systems. Blockchain provides an auditable framework that can track the origin of data and the models used by AI, thereby addressing the challenge of explainable AI. This helps improve trust in the data and the recommendations that AI provides. Furthermore, blockchain can enhance data security by providing a secure platform to store and distribute AI models.",
        image: '/images/dao/authenticity.png',
        icon: '',
        link: '#',
        linkText: 'Learn more',
        orbitImage: "/images/dao/3d_elements_circle.png",
    },
    {
        id: 1,
        title: 'Augmenting Intelligence',
        body: 'AI systems can analyze and process large amounts of data with remarkable speed and accuracy. By connecting AI to blockchain, we can bring intelligence to business networks. Blockchain can provide access to large volumes of data, enabling AI to deliver more actionable insights, manage data usage, and create a transparent and trustworthy data economy.',
        image: '/images/dao/augmentation.png',
        icon: '',
        link: '#',
        linkText: 'Learn more',
        orbitImage: '/images/dao/3d_elements_circle.png',
    },
    {
        id: 2,
        title: 'Automated Processes',
        body: 'By integrating AI, automation and blockchain, we can create new opportunities to streamline business processes and increase efficiency. For example, AI models can be embedded in smart contracts on a blockchain, allowing for the automatic execution of transactions, dispute resolution, product recalls, and sustainable shipping selections.',
        image: '/images/dao/automation.png',
        icon: '',
        link: '#',
        linkText: 'Learn more',
        orbitImage: '/images/dao/3d_elements_circle.png',
    },
    {
        id: 3,
        title: 'Healthcare Innovations',
        body: 'AI has the potential to revolutionize various aspects of healthcare, including treatment insights, patient data analysis, and pattern recognition. By utilizing blockchain to store electronic health records, healthcare organizations can work together to improve patient care while ensuring privacy protection.',
        image: '/images/dao/healthcare.png',
        icon: '',
        link: '#',
        linkText: 'Learn more',
        orbitImage: '/images/dao/3d_elements_circle.png',
    },
    {
        id: 4,
        title: 'Pharmaceutical Progress',
        body: 'Blockchain and sAI can bring much-needed visibility and traceability to the pharmaceutical industry. By combining advanced data analysis with a decentralized framework, clinical trials can benefit from increased data integrity, transparency, and automation. This can also include patient tracking, consent management, and more efficient data collection.',
        image: '/images/dao/lifesciences.png',
        icon: '',
        link: '#',
        linkText: 'Learn more',
        orbitImage: '/images/dao/3d_elements_circle.png',
    },
    {
        id: 5,
        title: 'Financial Services Transformation',
        body: 'By leveraging the capabilities of blockchain and AI, the financial services industry can experience increased trust, reduced friction in multi-party transactions, and accelerated transaction speeds. The loan process, for example, can be streamlined by granting consent for access to personal records stored on the blockchain and utilizing automated processes for evaluating the application.',
        image: '/images/dao/financial_services.png',
        icon: '',
        link: '#',
        linkText: 'Learn more',
        orbitImage: '/images/dao/3d_elements_circle.png',
    },
    {
        id: 6,
        title: 'Supply Chain Optimization',
        body: 'AI and blockchain are transforming supply chains across various industries, digitizing processes, and adding intelligence and automation. For instance, manufacturers can track carbon emissions data at the product or parts level, providing accuracy and intelligence to decarbonization efforts.',
        image: '/images/dao/supply_chain.png',
        icon: '',
        link: '#',
        linkText: 'Learn more',
        orbitImage: '/images/dao/3d_elements_circle.png',
    },
]