import Benefits from "components/dao/Benefits";
import Intro from "components/dao/Intro";
import Participate from "components/dao/Participate";
import Newsletter from "components/dao/newsletter";
import Meta from "components/Meta";
import TrustedPartner from "components/dao/TrustedPartner";
import { getDynamicAITitle } from "lib/title-ai";
import Hero from "components/hero/hero";
import Partners from "components/partners/Partners";

type Props = {
  hero: any;
};

const Home = ({content}: any) => {
  return (
    <div>
      <Meta title={getDynamicAITitle()} />
      <Hero hero={content.hero} />
      <Partners />
      <Intro />
      <Benefits />
      <Participate />
      <TrustedPartner />
      <Newsletter />
    </div>
  );
};

export default Home;
