/* eslint-disable @next/next/no-img-element */
import { getPartners } from "@/sanity/sanity-utils";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

interface IPartner {
  _id: string;
  link: string;
  logo: string;
  text: string;
  alt: string;
  name: string;
}

const Partners = () => {
  const [partners, setPartners] = useState<IPartner[]>([]);
  async function fetchPartners() {
    const partnersData = await getPartners();
    console.log(partnersData)
    setPartners(partnersData);
  }

  useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <>
      <div className="bg-gray-500 dark:bg-jakarta-900">
        <div className="container">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-10">
            {partners && partners.map((item) => (
              <Link
                className="flex items-center justify-center w-32 mb-8 sm:mb-0"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                key={item._id}
              >
                <img
                  src={`${item.logo}`}
                  alt={item.name}
                />
                <p className="font-semibold text-gray-400 text-md">{item.text}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
