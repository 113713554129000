import HeadLine from "../headLine";
import Image from "next/legacy/image";
import DaoBlock from "./DaoBlock"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { FormEvent, useState } from "react";
import { ThreeDots } from "react-loader-spinner"

const Newsletter = () => {
  const MAILCHIMP_URL = process.env.NEXT_PUBLIC_MAILCHIMP_FORM_ADDRESS;
  const [error, setError] = useState<string | null>(null);

  return (
    <section className="relative py-24">
      <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <Image
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full"
          layout="fill"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
        <Image
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full"
          layout="fill"
        />
      </picture>

      <div className="container">
        {/* Start global distribute and dao idea */}
        <div className="lg:flex lg:space-x-12">
          <DaoBlock />
        </div>
        {/* End global distribute and dao idea */}

        {/* start call to action weekly digest */}
        <div className="flex rounded-2.5xl bg-white px-12 py-20 text-center dark:bg-jakarta-700">
          <div className="hidden xl:block">
            <Image
              src="/images/home/newsletter_image.png"
              alt="weekly digest"
              width={350}
              height={350}
            />
            <p className="text-xs text-left text-deep-orange-600">
              Our bots are working hard to send you the latest news<br />
              <span className="text-gray-400">[ Image generated by Midjourney ]</span>
            </p>
          </div>
          <div>
            <HeadLine
              text="AI Insights Weekly: A Futuristic Fix for Tech-Hungry Minds"
              classes="mb-5 font-display text-3xl text-jakarta-700 dark:text-white"
              image={null}
              pera={null}
            />

            <p className="mx-auto max-w-2xl text-center text-lg text-jakarta-700 dark:text-white">
              Step into the future and join the AI avantgarde by subscribing to Accesspoint&apos;s newsletter - a weekly dose of technological wizardry and artificial intelligence magic, beamed directly to your inbox!
            </p>
            {/* <p>{process.env.NEXT_PUBLIC_MAILCHIMP_FORM_ADDRESS}</p> */}

            <div className="mx-auto mt-7 max-w-md text-center">
              <MailchimpSubscribe url={MAILCHIMP_URL} render={({ subscribe, status, message }) => {
                return (
                  <form className="relative" onSubmit={
                    (e: FormEvent<HTMLFormElement>) => {
                      e.preventDefault()
                      setError(null)
                      const data = new FormData(e.currentTarget);
                      subscribe({
                        EMAIL: data.get("email") as string,
                      })
                      e.currentTarget.reset()
                    }
                  }>
                    <input
                      disabled={status === "sending"}
                      type="email"
                      name="email"
                      placeholder={status === "sending" ? "wait..." : "Email address"}
                      className="dark:bg-jakarta-700 lowercase placeholder:normal-case dark:border-jakarta-600 focus:ring-accent border-jakarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
                    />
                    <button type="submit" className="hover:bg-accent-dark font-sans bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white">
                      Subscribe
                    </button>
                    {status === "sending" && <div className="container flex items-center justify-center text-[#ccc] text-2xs">
                      <ThreeDots
                        height="30"
                        width="30"
                        radius="9"
                        color="#ccc"
                        ariaLabel="three-dots-loading"
                        visible={true}
                      />
                      <span className="ml-2">
                        Our robots are processing your request...
                      </span>
                    </div>}
                    {status === "error" || error && <p className="text-red text-sm mt-1">{message}</p>}
                    {status === "success" && <p className="text-light-green-800 text-sm mt-1">{message}</p>}
                  </form>
                )
              }} />
            </div>
          </div>
        </div>
        {/* end call to action weekly digest */}
      </div>
    </section>
  );
};

export default Newsletter;
