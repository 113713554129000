import Partner1 from "./Partner1";
import Partner2 from "./Partner2";

const TrustedPartner = () => {
  return (
    <>
      {/* <!-- Partners --> */}
      <section className="overflow-hidden py-24 dark:bg-jakarta-900">
        <div className="container">
          <div className="mx-auto mb-14 max-w-xl text-center">
            <h2 className="mb-6 text-center font-display text-3xl font-medium text-jakarta-700 dark:text-white">
              Unleashing AI Potential with Our Partners
            </h2>
            <p className="text-lg dark:text-jakarta-300">
              The company has partnered with leading companies and organizations in AI and blockchain
              to bring cutting-edge solutions and a wealth of expertise. These partnerships
              help the company stay at the forefront of technological advancements and drive
              progress towards a better future powered by AI and blockchain.
            </p>
          </div>
        </div>
        <div className="mb-8 flex animate-marqueeRight space-x-8">
          <Partner1 />
        </div>
        {/* End .animate-marqueeRight */}

        <div className="flex animate-marquee space-x-8">
          <Partner2 />
        </div>
        {/* animate-marquee */}
      </section>
      {/* <!-- end partners -->  */}
    </>
  );
};

export default TrustedPartner;
