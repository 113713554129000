import BenefitContent from "./BenefitContent";

interface IBenefitItem {
  _id: string;
  title: string;
  body: string;
  image: string;
  orbitImage: string;
  alt: string;
}
interface IBenefitContent {
  title: string;
  body: string;
  items: IBenefitItem[];

}

const Benefits = () => {
  return (
    <>
      {/* <!-- Benefits --> */}
      <section className="py-24 dark:bg-jakarta-900">
        <div className="container">
          <div className="max-w-xl mx-auto mb-16 text-left">
            <h2 className="mb-6 text-3xl font-medium font-display text-jakarta-700 dark:text-white">
              Combined values of blockchain and AI
            </h2>
            <p className="leading-tight dark:text-jakarta-300">
              AI and Blockchain are two of the most talked about technologies of our time, and the integration of these two cutting-edge solutions offers tremendous potential for growth, security, and efficiency. By combining blockchain&apos;s ability to provide secure and authentic data storage and management with AI&apos;s power to analyze and utilize that data, we create a powerful solution that is capable of streamlining automation processes, improving decision-making, and enhancing data security.
            </p>
          </div>
          <BenefitContent />
        </div>
      </section>
      {/* <!-- end benefits --> */}
    </>
  );
};

export default Benefits;
