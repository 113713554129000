import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import Home from "components/Home";
import { getHeroContent } from "@/sanity/sanity-utils";

type Props = {
  hero: any;
}

const HomePage = (props:Props) => {
  const { scrollRef } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  });

  return (
    <>
      <Home content={props} />
    </>
  );
}

export async function getStaticProps(context) {
  const hero = await getHeroContent();
  return {
    props: {hero:hero[0]}, // will be passed to the page component as props
  }
}

export default HomePage;