/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Button,
} from "@material-tailwind/react";
import { faq_list } from "data/faq_data";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${id === open ? "rotate-180" : ""
        } h-4 w-4 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const FaqAccordion = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="mb-8">
      {
        faq_list.map((item, index) => {
          if(index>4) return null;
          return (
            <Accordion
              className="accordion-item mb-5 overflow-hidden rounded-lg border border-jakarta-100 dark:border-jakarta-600"
              open={open === index + 1}
              icon={<Icon id={index + 1} open={open} />}
              key={index}
            >
              <AccordionHeader
                className="accordion-button collapsed relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-sm text-jakarta-700 dark:bg-jakarta-700 dark:text-white"
                onClick={() => handleOpen(index + 1)}
              >
                {item.question}
              </AccordionHeader>
              <AccordionBody className="accordion-body border-t border-jakarta-100 bg-white p-4 dark:border-jakarta-600 dark:bg-jakarta-700">
                {item.answer}
              </AccordionBody>
            </Accordion>
          );
        }
        )
      }
    </div>
  );
};

export default FaqAccordion;
