import Image from "next/image";

const Partner2 = () => {
  const partnerItem = [
    { id: 0, image: "anodot.png", link: "", title: "", description: "" },
    { id: 1, image: "algorex.png", link: "", title: "", description: "" },
    { id: 2, image: "h2o.jpg", link: "", title: "", description: "" },
    { id: 3, image: "alteryx.png", link: "", title: "", description: "" },
    { id: 4, image: "accubits.png", link: "", title: "", description: "" },
    { id: 5, image: "ayasdi.png", link: "", title: "", description: "" },
    { id: 6, image: "cognitivescale.png", link: "", title: "", description: "" },
    { id: 7, image: "Infosys-Nia.png", link: "", title: "", description: "" },
    { id: 8, image: "data_robot.png", link: "", title: "", description: "" },
  ];
  return (
    <>
      {partnerItem.map((item, i) => (
        <div
          className="flex w-72 flex-shrink-0 items-center justify-center rounded-2.5xl border border-jakarta-100 bg-white p-6"
          key={i}
        >
          <Image width={150} height={50} src={`/images/partners/${item.image}`} alt="partner item" />
        </div>
      ))}
    </>
  );
};

export default Partner2;
