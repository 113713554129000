import Image from "next/legacy/image";
import { getHeroContent } from "@/sanity/sanity-utils";
import { useEffect, useState } from "react";

interface IHeroContent {
  _createdAt: string;
  _id: string;
  title: string;
  subtitle: string;
  image: string;
  alt: string;
}

type Props = {
  hero: IHeroContent
}

const Hero = ({ hero }: Props) => {

  console.log(hero.image)

  return <>
    <section className={`relative h-screen  `}>
      {hero.image && <Image
        src={hero.image}
        // src={image_url}
        alt={hero.image}
        className={`absolute bg-blue-gray-900 dark:bg-transparent
        inset-0 -z-10 h-full w-full object-cover`}
        layout="fill"
      />}

      <div className={`ml-auto mr-auto h-full max-w-[91rem] px-4
                         bg-gradient-to-r from-gray-900 to-transparent md:from-transparent
      `}>
        <div className="grid items-center w-full h-full gap-4 lg:grid-cols-12 ">
          <div className="relative flex flex-col items-center justify-center h-full col-span-5 py-10 -tracking-tighter md:items-start lg:py-20">
            <h1 className="max-w-sm pt-4 pl-4 mb-6 text-4xl text-center text-white rounded-t font-title xl:text-5xl md:text-left lg:text-6xl xl:pt-0 ">
              {hero.title}
            </h1>
            <p className="max-w-md pl-4 mb-8 text-lg text-center text-gray-100 md:text-left">
              {hero.subtitle}
            </p>
            <div className="flex space-x-4">
            </div>
          </div>
          <div className="relative hidden h-full col-span-6 col-start-7 md:block">
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default Hero;
