import Image from "next/image";

const Partner1 = () => {
  const partnerItem = [
    { id: 0, image: "ibm_watson.png", link: "", title: "", description: "" },
    { id: 1, image: "google_ai.png", link: "", title: "", description: "" },
    { id: 2, image: "openai.png", link: "", title: "", description: "" },
    { id: 3, image: "alteryx.png", link: "", title: "", description: "" },
    { id: 4, image: "pytorch.png", link: "", title: "", description: "" },
    { id: 5, image: "baidu_cloud.png", link: "", title: "", description: "" },
    { id: 6, image: "hugging_face.png", link: "", title: "", description: "" },
    { id: 7, image: "tensorflow.png", link: "", title: "", description: "" },
    { id: 8, image: "nvidia_dl.png", link: "", title: "", description: "" },
  ];
  return (
    <>
      {partnerItem.map((item, i) => (
        <div
          className="flex w-72 
          flex-shrink-0 items-center justify-center rounded-2.5xl border border-jakarta-100 bg-white p-6"
          key={i}
        >
          <Image width={150} height={50} src={`/images/partners/${item.image}`} alt={item.title} />
        </div>
      ))}
    </>
  );
};

export default Partner1;
