import { groq } from 'next-sanity'
import client from './client'


export async function getProjectId() {
    return client.fetch(groq`*[_type == "project"]{
        _id,
        _createdAt,
        name,
        "slug": slug.current,
        "image": image.asset->url,
        url,
        content,
    }`)

}

export async function getHeroContent() {
    return client.fetch(
        `*[_type == "hero"]{
            "image": image.asset->url,
            "alt": image.alt,
            title,
            subtitle,
        }`
    )
}

export async function getPartners() {
    return client.fetch(
        `*[_type == "partner"]{
            _id,
            "logo": logo.asset->url,
            "alt": logo.alt,
            name,
            link,
            text,
        }`
    )
}

export async function getIntro() {
    return client.fetch(
        `*[_type == "intro"]{
            "image": image.asset->url,
            "alt": image.alt,
            title,
            subtitle,
            description,
        }`
    )
}